import * as React from 'react';
import Intro from './intro';

type HomeProps = {};

const Index: React.FunctionComponent<HomeProps> = (props) => {
  return (
    <div {...props}>
      <Intro />
    </div>
  );
};

export default Index;
