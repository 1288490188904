import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../../components/social-profile/social-profile';
import introStyle from './intro.module.scss';
import {
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoGithub,
  IoMdMail
} from 'react-icons/io';

type IntroProps = {};

const SocialLinks = [
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/btphamm/',
    tooltip: 'Instagram',
  },
  {
    icon: <IoLogoLinkedin />,
    url: 'https://www.linkedin.com/in/baothienpham/',
    tooltip: 'Linked In',
  },
  {
    icon: <IoLogoGithub />,
    url: 'https://github.com/baotpham',
    tooltip: 'Github',
  },
  {
    icon: <IoMdMail />,
    url: 'mailto:baotpham7@gmail.com',
    tooltip: 'Email',
  },
];

const Intro: React.FunctionComponent<IntroProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/author.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 210, maxHeight: 210, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  const { author, about } = Data.site.siteMetadata;
  const AuthorImage = Data.avatar.childImageSharp.fluid;

  return (
    <div className={introStyle.introWrapper}>
      <div className={introStyle.introImage}>
        <Image fluid={AuthorImage} alt="author" />
      </div>
      <h1 className={introStyle.introTitle}>
        Hey! I’m <b>{author}</b>.
      </h1>
      <p className={introStyle.introDescription}>{about}</p>
      <SocialProfile items={SocialLinks} />
    </div>
  );
};

export default Intro;
